/* eslint-disable */
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

$(() => {
    $('.form').on('submit', (e) => {
        e.preventDefault();

        const email = $('input[name=email]').val();

        $('button[type=submit]').text('Отправка...').prop('disabled', true);

        $.post(
            "/send.php",
            { email },
            (response) => {
                if (!response.error) {
                    $('input[name=email]').val('');
                    $('.form').hide();
                    $('.success').show();
                }
            }
        );

        return false;
    });
});